// jshint ignore: start
import React, { useState, useRef, useEffect } from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { makeStyles } from '@material-ui/core/styles';
import { globalHistory, navigate } from "@reach/router"
import queryString from 'query-string'
import firebase from 'firebase/app'
import FormLabel from '@material-ui/core/FormLabel'
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    Elements,
    StripeProvider
} from 'react-stripe-elements';
import '../components/addPaymentStyles.css';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(3, 2),
        backgroundColor: "rgb(250, 225, 255, 0.5)"
    },
    paperPayed: {
        padding: theme.spacing(3, 2),
        backgroundColor: "rgb(232, 255, 225, 0.5)"
    },
    wrapper: {
        margin: theme.spacing(3),
        position: 'relative'
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    form: {
        textAlign: 'left',
        top: 0,
        flex: '1 0 auto',
        height: '100%',
        display: 'flex',
        outline: 0,
        zIndex: 1200,
        overflowY: 'none',
        flexDirection: 'column',
        // -webkit-overflow-scrolling: touch;
    }
}));

const createOptions = (fontSize, padding) => {
    return {
        style: {
            base: {
                fontSize,
                color: '#424770',
                letterSpacing: '0.025em',
                fontFamily: 'Source Code Pro, monospace',
                '::placeholder': {
                    color: '#aab7c4',
                },
                padding,
            },
            invalid: {
                color: '#9e2146',
            },
        },
    };
};

function AuthContent(props) {
    const mypath = globalHistory.location.pathname
    const qs = queryString.parse(globalHistory.location.search)
    const [token, setToken] = useState(qs.token ? qs.token : "")
    const [newCard, setNewCard] = useState(qs.card ? qs.card : "")
    const [ready, setReady] = useState(false);
    const [card, setCard] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [payed, setPayed] = useState(false);
    const [disabled, setDisabled] = useState(false)
    const [stripe, setStripe] = useState(null);
    const timer = useRef();
    const classes = useStyles();

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    const paperClassname = clsx({
        [classes.paper]: !payed,
        [classes.paperPayed]: payed,
    });

    const handleSubmit = (ev) => {
        ev.preventDefault();
        if (payed) {
            navigate('/')
            return
        }
        setLoading(true)
        const query = {
            token,
            newCard   
        }
        firebase.functions().httpsCallable('paymentIntentUpdate')({
                query
            })
            .then(result => {
                if (result.data) {
                    const {code,onHoldKey,payment_intent,customer,client_secret, isbranding} = result.data
                    if (code === 400) {
                        stripe.createPaymentMethod({
                            type: 'card',
                            card: card
                        }).then((result) => {
                            handleUpdatePMCostumer(customer, onHoldKey, result.paymentMethod.id, client_secret, isbranding)
                        });
                    }
                }
            });
    }

    const handleUpdatePMCostumer = (customer, onHoldKey, pm, client_secret, isbranding) => {
        const query = {token, customer, pm}
        firebase.functions().httpsCallable('updatePMAfterPayment')({
                query
            })
            .then(result => {
                if (result.data) {
                    const {code} = result.data
                    if (code === 400) {
                           stripe.confirmCardPayment(client_secret, {
                               payment_method: pm
                           }).then((result) => {
                               if (result.error) {
                                   console.log("ERRO", result.error)
                               } else {
                                   if (result.paymentIntent.status === "succeeded") {
                                    handleUpdatePlan(onHoldKey, customer, isbranding)
                                   }
                               }
                           });
                    }
                }
            });
    }

    const handleBlur = () => {};
    const handleChange = (change) => {
        setError("")
    };
    const handleClick = () => {};
    const handleFocus = () => {};
    const handleReady = () => {
        setReady(true)
    };
    const handleCardReady = (evt) => {
        setCard(evt)
    };

    const handleAuth = () => {
        if (payed) {
            navigate('/')
            return
        }
        setLoading(true)
        const query = {token}
        firebase.functions().httpsCallable('paymentIntentAuth')({
            query
        })
        .then(result => {
            if (result.data) {
                const {code, onHoldKey, customer, client_secret, payment_method, isbranding} = result.data
                if (code === 400) {
                    stripe.handleCardPayment(
                        client_secret, {
                            payment_method: payment_method
                        }
                    ).then((result) => {
                        if (result.error) {
                            console.log("ERRO: ", result.error)
                        } else {
                            if (result.paymentIntent.status === "succeeded") {
                                handleUpdatePlan(onHoldKey, customer, isbranding)
                            }
                        }
                    });
                } else if (code === 402) {
                    setPayed(true)
                    setSuccess(true)
                    setLoading(false)
                }
            }
        });
    }

    const handleUpdatePlan = (onHoldKey, customer, isbranding) => {
        const query = {onHoldKey: onHoldKey, customer: customer, isbranding: isbranding}
        firebase.functions().httpsCallable('updatePlanAuth')({
            query
        })
        .then(result => {
            if (result.data) {
                const {code} = result.data
                if (code === 400) {
                    setPayed(true)
                    setSuccess(true)
                } else {
                }
                setLoading(false)
            }
        });
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            if (!ref.current) {
                ref.current = true;
                if (window.Stripe) {
                    setStripe(window.Stripe('pk_live_kJcTeudTDYkZCCS8Qc27IDzd'))
                } else {
                    document.querySelector('#stripe-js').addEventListener('load', () => {
                        setStripe(window.Stripe('pk_live_kJcTeudTDYkZCCS8Qc27IDzd'))
                    });
                }
            }
            ref.current = value
        }, [value]);
        return ref.current;
    }

    const prevValue = usePrevious();
    useEffect(() => {
        if (prevValue) {

        }
    }, [])

    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    return (
        <div>
            <SEO title={ newCard ? 'Update payment details!' : 'Payment Authentication'} />
            <Typography style={{padding: 24}} component="p" variant="h5">{ newCard ? 'Please update your payment details!' : 'Payment Authentication'}</Typography>
            {newCard !== "new" &&
                <Paper className={paperClassname}>
                    <Typography component="p">
                        {payed ? 'This Payment was already successfully confirmed.' : 'Your Card was declined. This transaction requires authentication'}
                    </Typography>
                </Paper>
            }
                { newCard === "new" ?
                    <StripeProvider {...{stripe}}>
                        <Elements>
                            <form className={classes.form} onSubmit={handleSubmit}>
                                <Paper style={{textAlign: 'center', marginBottom: 20}} className={paperClassname}>
                                    <Typography component="p">
                                    {payed ? 'This Payment was already successfully confirmed.' : 'Your Card was declined. Update your payment details'}
                                    </Typography>
                                </Paper>
                                <FormLabel error={error === "incomplete_number"}>
                                    Card number
                                    <CardNumberElement
                                    disabled={loading || payed}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onReady={handleCardReady}
                                    {...createOptions(props.fontSize)}
                                    />
                                </FormLabel>
                                <FormLabel error={error === "incomplete_expiry"}>
                                    Expiration date
                                    <CardExpiryElement
                                    disabled={loading || payed}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onReady={handleReady}
                                    {...createOptions(props.fontSize)}
                                    />
                                </FormLabel>
                                <FormLabel error={error === "incomplete_cvc"}>
                                    CVC
                                    <CardCVCElement
                                    disabled={loading || payed}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onReady={handleReady}
                                    {...createOptions(props.fontSize)}
                                    />
                                </FormLabel>
                                <div style={{margin: '24px 0'}} className={classes.wrapper}>
                                    <Button
                                        style={{width: '100%'}}
                                        variant="contained"
                                        color="primary"
                                        className={buttonClassname}
                                        disabled={loading || disabled}
                                        type="submit"
                                    >
                                        {!success && !payed ? 'SAVE' : payed ? 'Go Back' : 'Payment Successful!'}
                                    </Button>
                                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </form>
                        </Elements>
                    </StripeProvider>
                :
                    <div className={classes.wrapper}>
                        <Button
                        variant="contained"
                        color="primary"
                        className={buttonClassname}
                        disabled={loading || disabled}
                        onClick={handleAuth}
                        >
                        {!success && !payed ? 'Authenticate' : payed ? 'Go Back' : 'Payment Successful!'}
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                }
        </div>
    )
}

const PaymentPage = () => (
  <Layout>
    <div style={{textAlign: 'center', width: 500,marginLeft: 'auto',marginRight: 'auto'}}>
        <AuthContent/>
    </div>
  </Layout>
)

export default PaymentPage
